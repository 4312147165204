'use client'; // This directive is required for client-side code in the app directory

import { useState } from 'react';
import Link from 'next/link';
import { LandingPage } from '@/components/landing-page';

const HomePage = () => {
  const [title, setTitle] = useState('Welcome to the Speech APP');

  return (
    <div className='flex flex-col min-h-screen'>
      <LandingPage />
    </div>
  );
};

export default HomePage;
