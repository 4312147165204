"use client";
import Image from "next/image";
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Mic, FileText, Globe, Users, Menu, X } from "lucide-react";
import Link from "next/link";
import { motion, AnimatePresence } from "framer-motion";

export function LandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsMenuOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navItems = [
    { href: "/record", label: "Record" },
    // { href: "/VideoToAudio", label: "Video to Audio" },
    { href: "/settings", label: "Settings" },
    // { href: "/stream", label: "Stream" },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-green-50 to-white">
      {/* Mobile Navigation */}
      <AnimatePresence>
        {isMobile && isMenuOpen && (
          <motion.nav
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="md:hidden bg-green-600 py-4 px-6 shadow-md absolute top-20 left-0 right-0 z-40"
          >
            {navItems.map((item) => (
              <Link
                key={item.href}
                href={item.href}
                className="block py-2 text-white hover:text-gray-200 font-medium text-lg transition-colors duration-200"
                onClick={() => setIsMenuOpen(false)}
              >
                {item.label}
              </Link>
            ))}
          </motion.nav>
        )}
      </AnimatePresence>

      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-20 md:py-32 lg:py-44">
          <div className="container mx-auto px-4 md:px-6 max-w-5xl">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="flex flex-col items-center space-y-8 text-center"
            >
              <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl lg:text-7xl max-w-4xl">
                Transcribe, <span className="text-green-600">Translate</span>{" "}
                and Broadcas Speeches with Ease
              </h1>
              <p className="max-w-2xl text-gray-600 md:text-xl lg:text-2xl">
                TalkTranslate: The ultimate voice transcription app for
                faith-based organizations, conferences, and events. Capture,
                transcribe, translate and broadcast your speeches effortlessly &
                in realtime with your audience.
              </p>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <Link
                  href="https://calendly.com/sas70/translate-30-min"
                  className="bg-green-600 hover:bg-green-700 text-white px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105"
                >
                  Get Started
                </Link>
                <Link
                  href="#features"
                  className="border-green-600 text-green-600 hover:bg-green-50 px-8 py-3 rounded-full transition-all duration-300"
                >
                  Learn More
                </Link>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="w-full py-20 md:py-32 bg-white">
          <div className="container mx-auto px-4 md:px-6 max-w-6xl">
            <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16">
              Key Features
            </h2>
            <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-3">
              {[
                {
                  icon: FileText,
                  title: "Accurate Transcription",
                  description:
                    "State-of-the-art AI technology ensures precise transcription & translation of most languages including Arabic.",
                },
                {
                  icon: Globe,
                  title: "Multi-language Support",
                  description:
                    "Transcribe and translate talks in multiple languages, including Arabic.",
                },
                {
                  icon: Users,
                  title: "Easy Sharing",
                  description:
                    "Get inclusive & closer to your audience by realtime multi-language translation of talks without any hassle.",
                },
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="flex flex-col items-center text-center p-6 bg-green-50 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <feature.icon className="h-16 w-16 text-green-600 mb-6" />
                  <h3 className="text-2xl font-bold mb-4">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section
          id="testimonials"
          className="w-full py-20 md:py-32 bg-green-50"
        >
          <div className="container mx-auto px-4 md:px-6 max-w-6xl">
            <h2 className="text-3xl font-bold tracking-tight sm:text-5xl text-center mb-16">
              Trusted by reputable organisations
            </h2>
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {[
                {
                  quote:
                    "TalkTranslate has revolutionized how we share our Friday khutbahs with our community. It's an invaluable tool for dawah.",
                  author: "Imam Abdullah, New York Central Mosque",
                },
                {
                  quote:
                    "The multi-language support has helped us reach a wider audience and break down language barriers in our diverse community.",
                  author: "Ustadh Yusuf, London Islamic Center",
                },
                {
                  quote:
                    "The accuracy of the translation, especially with Islamic terminology, is impressive. It saves us precious time & resources.",
                  author: "Sister Aisha, Toronto Masjid",
                },
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <p className="text-gray-600 mb-6 italic">
                    &ldquo;{testimonial.quote}&rdquo;
                  </p>
                  <p className="font-bold text-green-600">
                    - {testimonial.author}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </main>
      <footer className="py-8 w-full border-t bg-white">
        <div className="container mx-auto px-4 md:px-6 max-w-6xl">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-600 mb-4 md:mb-0">
              © 2024 TalkTranslate. All rights reserved.
            </p>
            <nav className="flex gap-6">
              <Link
                className="text-sm text-gray-600 hover:text-green-600 transition-colors"
                href="#"
              >
                Terms of Service
              </Link>
              <Link
                className="text-sm text-gray-600 hover:text-green-600 transition-colors"
                href="#"
              >
                Privacy
              </Link>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  );
}
